var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table-simple",
        {
          staticStyle: { "max-height": "65vh", "font-size": "12px" },
          attrs: { bordered: "", responsive: "", "sticky-header": "" },
        },
        [
          _c("colgroup", [
            _c("col", { staticStyle: { width: "130px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
            _c("col", { staticStyle: { width: "100px" } }),
            _c("col", { staticStyle: { width: "105px" } }),
          ]),
          _c(
            "b-thead",
            { attrs: { "head-variant": "light" } },
            [
              _c(
                "b-tr",
                [
                  _c(
                    "b-th",
                    {
                      staticClass: "sticky-header",
                      staticStyle: { left: "0" },
                      attrs: { rowspan: "2" },
                    },
                    [_vm._v(" 구분 ")]
                  ),
                  _c("b-th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 닥터인포 홈 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 메디컬트렌드 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 약품정보 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 웹세미나 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 설문조사 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "2" } }, [
                    _vm._v(" 포인트몰 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [_vm._v(" 이벤트 ")]),
                  _c("b-th", { attrs: { colspan: "4" } }, [
                    _vm._v(" 공지사항 "),
                  ]),
                  _c("b-th", { attrs: { colspan: "4" } }, [_vm._v(" 전체 ")]),
                ],
                1
              ),
              _c(
                "b-tr",
                [
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 유입자 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭 수 "),
                  ]),
                  _c("b-th", { staticStyle: { top: "38.55px" } }, [
                    _vm._v(" 클릭자 수 "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.data.length > 0
            ? _c(
                "b-tbody",
                _vm._l(_vm.data, function (m, idx) {
                  return _c(
                    "b-tr",
                    { key: "tr-" + idx },
                    [
                      _c(
                        "b-td",
                        {
                          staticClass: "sticky-row",
                          staticStyle: { left: "0" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.data.length === 1 && m.isPrefixSum
                                  ? "누적 수"
                                  : m.isPrefixSum
                                  ? "합계"
                                  : m.date
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.homeEntryTotalMemberCount +
                                m.homeEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.homeEntryGroupMemberCount +
                                m.homeEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.medicalTrendEntryTotalMemberCount +
                                m.medicalTrendEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.medicalTrendEntryGroupMemberCount +
                                m.medicalTrendEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.medicalTrendClickTotalMemberCount +
                                m.medicalTrendClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.medicalTrendClickGroupMemberCount +
                                m.medicalTrendClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.drugInfoEntryTotalMemberCount +
                                m.drugInfoEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.drugInfoEntryGroupMemberCount +
                                m.drugInfoEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.drugInfoClickTotalMemberCount +
                                m.drugInfoClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.drugInfoClickGroupMemberCount +
                                m.drugInfoClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.webSeminarEntryTotalMemberCount +
                                m.webSeminarEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.webSeminarEntryGroupMemberCount +
                                m.webSeminarEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.webSeminarClickTotalMemberCount +
                                m.webSeminarClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.webSeminarClickGroupMemberCount +
                                m.webSeminarClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.surveyEntryTotalMemberCount +
                                m.surveyEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.surveyEntryGroupMemberCount +
                                m.surveyEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.surveyClickTotalMemberCount +
                                m.surveyClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.surveyClickGroupMemberCount +
                                m.surveyClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.pointMallEntryTotalMemberCount +
                                m.pointMallEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.pointMallEntryGroupMemberCount +
                                m.pointMallEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.eventEntryTotalMemberCount +
                                m.eventEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.eventEntryGroupMemberCount +
                                m.eventEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.eventClickTotalMemberCount +
                                m.eventClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.eventClickGroupMemberCount +
                                m.eventClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.noticeEntryTotalMemberCount +
                                m.noticeEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.noticeEntryGroupMemberCount +
                                m.noticeEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.noticeClickTotalMemberCount +
                                m.noticeClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.noticeClickGroupMemberCount +
                                m.noticeClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.allEntryTotalMemberCount +
                                m.allEntryTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.allEntryGroupMemberCount +
                                m.allEntryGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.allClickTotalMemberCount +
                                m.allClickTotalNonMemberCount
                            )
                          )
                        ),
                      ]),
                      _c("b-td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$numberFormatter")(
                              m.allClickGroupMemberCount +
                                m.allClickGroupNonMemberCount
                            )
                          )
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "b-tbody",
                [
                  _c(
                    "b-tr",
                    [
                      _c("b-td", { attrs: { colspan: "33" } }, [
                        _c(
                          "div",
                          { attrs: { role: "alert", "aria-live": "polite" } },
                          [
                            _c("div", { staticClass: "mx-2 my-2" }, [
                              _vm._v(" 일치하는 데이터가 없습니다. "),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }