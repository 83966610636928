<template>
  <div>
    <b-table-simple
      bordered
      responsive
      sticky-header
      style="max-height: 65vh; font-size: 12px"
    >
      <colgroup>
        <!-- 구분 -->
        <col style="width: 130px">
        <!-- 닥터인포 홈 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 메디컬트렌드 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 약품정보 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 웹세미나 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 설문조사 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 포인트몰 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 이벤트 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 공지사항 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <!-- 전체 -->
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
        <col style="width: 90px">
      </colgroup>
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            rowspan="3"
            class="sticky-header"
            style="left: 0"
          >
            구분
          </b-th>
          <b-th colspan="4">
            닥터인포 홈
          </b-th>
          <b-th colspan="8">
            메디컬트렌드
          </b-th>
          <b-th colspan="8">
            약품정보
          </b-th>
          <b-th colspan="8">
            웹세미나
          </b-th>
          <b-th colspan="8">
            설문조사
          </b-th>
          <b-th colspan="4">
            포인트몰
          </b-th>
          <b-th colspan="8">
            이벤트
          </b-th>
          <b-th colspan="8">
            공지사항
          </b-th>
          <b-th colspan="8">
            전체
          </b-th>
        </b-tr>
        <b-tr>
          <!-- 닥터인포 홈 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <!-- 메디컬트렌드 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
          <!-- 약품정보 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
          <!-- 웹세미나 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
          <!-- 설문조사 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
          <!-- 포인트몰 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <!-- 이벤트 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
          <!-- 공지사항 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
          <!-- 전체 -->
          <b-th colspan="2">
            유입 수
          </b-th>
          <b-th colspan="2">
            유입자 수
          </b-th>
          <b-th colspan="2">
            클릭 수
          </b-th>
          <b-th colspan="2">
            클릭자 수
          </b-th>
        </b-tr>
        <b-tr>
          <!-- 닥터인포 홈 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 메디컬트렌드 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 약품정보 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 웹세미나 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 설문조사 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 포인트몰 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 이벤트 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 공지사항 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <!-- 전체 -->
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
          <b-th>
            회원
          </b-th>
          <b-th>
            비회원
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="data.length > 0">
        <b-tr
          v-for="(m, idx) in data"
          :key="`tr-${idx}`"
        >
          <b-td
            class="sticky-row"
            style="left: 0"
          >
            {{ (data.length === 1 && m.isPrefixSum) ? '누적 수' : (m.isPrefixSum ? "합계" : m.date) }}
          </b-td>
          <b-td>{{ m.homeEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.homeEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.homeEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.homeEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.medicalTrendClickGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.drugInfoClickGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.webSeminarClickGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.surveyClickGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.pointMallEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.pointMallEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.pointMallEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.pointMallEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.eventClickGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.noticeClickGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allEntryTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allEntryTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allEntryGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allEntryGroupNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allClickTotalMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allClickTotalNonMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allClickGroupMemberCount | $numberFormatter }}</b-td>
          <b-td>{{ m.allClickGroupNonMemberCount | $numberFormatter }}</b-td>
        </b-tr>
      </b-tbody>

      <b-tbody v-else>
        <b-tr>
          <b-td colspan="65">
            <div
              role="alert"
              aria-live="polite"
            >
              <div class="mx-2 my-2">
                일치하는 데이터가 없습니다.
              </div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
