<template>
  <div>
    <b-table-simple
      bordered
      responsive
      sticky-header
      style="max-height: 65vh; font-size: 12px"
    >
      <colgroup>
        <!-- 구분 -->
        <col style="width: 130px">
        <!-- 닥터인포 홈 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 메디컬트렌드 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 약품정보 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 웹세미나 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 설문조사 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 포인트몰 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 이벤트 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 공지사항 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
        <!-- 전체 -->
        <col style="width: 100px">
        <col style="width: 105px">
        <col style="width: 100px">
        <col style="width: 105px">
      </colgroup>
      <b-thead head-variant="light">
        <b-tr>
          <b-th
            rowspan="2"
            class="sticky-header"
            style="left: 0"
          >
            구분
          </b-th>
          <b-th colspan="2">
            닥터인포 홈
          </b-th>
          <b-th colspan="4">
            메디컬트렌드
          </b-th>
          <b-th colspan="4">
            약품정보
          </b-th>
          <b-th colspan="4">
            웹세미나
          </b-th>
          <b-th colspan="4">
            설문조사
          </b-th>
          <b-th colspan="2">
            포인트몰
          </b-th>
          <b-th colspan="4">
            이벤트
          </b-th>
          <b-th colspan="4">
            공지사항
          </b-th>
          <b-th colspan="4">
            전체
          </b-th>
        </b-tr>
        <b-tr>
          <!-- 닥터인포 홈 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <!-- 메디컬트렌드 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
          <!-- 약품정보 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
          <!-- 웹세미나 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
          <!-- 설문조사 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
          <!-- 포인트몰 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <!-- 이벤트 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
          <!-- 공지사항 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
          <!-- 전체 -->
          <b-th style="top: 38.55px;">
            유입 수
          </b-th>
          <b-th style="top: 38.55px;">
            유입자 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭 수
          </b-th>
          <b-th style="top: 38.55px;">
            클릭자 수
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="data.length > 0">
        <b-tr
          v-for="(m, idx) in data"
          :key="`tr-${idx}`"
        >
          <b-td
            class="sticky-row"
            style="left: 0"
          >
            {{ (data.length === 1 && m.isPrefixSum) ? '누적 수' : (m.isPrefixSum ? "합계" : m.date) }}
          </b-td>
          <b-td>{{ (m.homeEntryTotalMemberCount + m.homeEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.homeEntryGroupMemberCount + m.homeEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.medicalTrendEntryTotalMemberCount + m.medicalTrendEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.medicalTrendEntryGroupMemberCount + m.medicalTrendEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.medicalTrendClickTotalMemberCount + m.medicalTrendClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.medicalTrendClickGroupMemberCount + m.medicalTrendClickGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.drugInfoEntryTotalMemberCount + m.drugInfoEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.drugInfoEntryGroupMemberCount + m.drugInfoEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.drugInfoClickTotalMemberCount + m.drugInfoClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.drugInfoClickGroupMemberCount + m.drugInfoClickGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.webSeminarEntryTotalMemberCount + m.webSeminarEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.webSeminarEntryGroupMemberCount + m.webSeminarEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.webSeminarClickTotalMemberCount + m.webSeminarClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.webSeminarClickGroupMemberCount + m.webSeminarClickGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.surveyEntryTotalMemberCount + m.surveyEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.surveyEntryGroupMemberCount + m.surveyEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.surveyClickTotalMemberCount + m.surveyClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.surveyClickGroupMemberCount + m.surveyClickGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.pointMallEntryTotalMemberCount + m.pointMallEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.pointMallEntryGroupMemberCount + m.pointMallEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.eventEntryTotalMemberCount + m.eventEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.eventEntryGroupMemberCount + m.eventEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.eventClickTotalMemberCount + m.eventClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.eventClickGroupMemberCount + m.eventClickGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.noticeEntryTotalMemberCount + m.noticeEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.noticeEntryGroupMemberCount + m.noticeEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.noticeClickTotalMemberCount + m.noticeClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.noticeClickGroupMemberCount + m.noticeClickGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.allEntryTotalMemberCount + m.allEntryTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.allEntryGroupMemberCount + m.allEntryGroupNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.allClickTotalMemberCount + m.allClickTotalNonMemberCount) | $numberFormatter }}</b-td>
          <b-td>{{ (m.allClickGroupMemberCount + m.allClickGroupNonMemberCount) | $numberFormatter }}</b-td>
        </b-tr>
      </b-tbody>

      <b-tbody v-else>
        <b-tr>
          <b-td colspan="33">
            <div
              role="alert"
              aria-live="polite"
            >
              <div class="mx-2 my-2">
                일치하는 데이터가 없습니다.
              </div>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import {
  BTableSimple, BThead, BTbody, BTr, BTh, BTd,
} from 'bootstrap-vue'
import { onMounted } from '@vue/composition-api'

export default {
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup() {
    onMounted(() => {
    })
  },
}
</script>

<style scoped>
.table-bordered th, td {
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle;
}

.sticky-header {
  position: sticky;
  z-index: 99 !important;
}

.dark-layout .sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #283046;
}

.sticky-row {
  position: sticky;
  z-index: 3 !important;
  background-color: #fff;
}
</style>
