<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              회원 구분
            </p>
            <b-form-radio-group
              v-model="query.viewType"
            >
              <b-form-radio value="summary">
                전체
              </b-form-radio>
              <b-form-radio value="detail">
                회원/비회원
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <p class="h5 mt-50 mr-75 ml-50">
              일별/누적
            </p>
            <b-form-radio-group
              v-model="query.isPrefixSum"
              @change="changePrefixSum"
            >
              <b-form-radio :value="false">
                일별 (기간:최대30일)
              </b-form-radio>
              <b-form-radio :value="true">
                누적 (기간:최대1년)
              </b-form-radio>
            </b-form-radio-group>
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="조회기간(Fr)"
              style="width: 240px"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="조회기간(To)"
              style="width: 240px"
            />
            <label>(*당일데이터 조회 불가)</label>
          </b-col>
          <b-col
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
            <b-button
              variant="outline-dark"
              class="btn-search ml-1"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>엑셀 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-card>
      <summary-grid
        v-if="viewType === 'summary'"
        :data="results"
      />

      <detail-grid
        v-else-if="viewType === 'detail'"
        :data="results"
      />
    </b-card>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import axios from '@axios'
import { incrementAlphabet } from '@core/utils/utils'
import { onMounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import SummaryGrid from './grid/SummaryGrid.vue'
import DetailGrid from './grid/DetailGrid.vue'

export default {
  components: {
    SummaryGrid,
    DetailGrid,
  },

  setup() {
    onMounted(() => {
      fetchData()
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const changePrefixSum = () => {
      if (query.value.isPrefixSum) {
        query.value.startDate = dayjs().add(-1, 'month').format('YYYY-MM-DD')
      } else {
        query.value.startDate = dayjs().add(-7, 'day').format('YYYY-MM-DD')
      }

      query.value.endDate = dayjs().add(-1, 'day').format('YYYY-MM-DD')
    }

    const query = ref({
      viewType: 'summary',
      startDate: dayjs().add(-7, 'day').format('YYYY-MM-DD'),
      endDate: dayjs().add(-1, 'day').format('YYYY-MM-DD'),
      isPrefixSum: false,
      status: null,
      filterStr: null,
    })

    const viewType = ref("summary")
    const headers = ref([])
    const results = ref([])
    const pageCount = ref(15)

    const fetchData = () => {
      const frDate = dayjs(query.value.startDate)
      const toDate = dayjs(query.value.endDate)

      if (query.value.isPrefixSum && Math.abs(frDate.diff(toDate, 'day')) > 365) {
        pushToast('warning', '조회 기간은 최대 1년을 넘을 수 없습니다.')
        return
      }
      if (query.value.isPrefixSum === false && Math.abs(frDate.diff(toDate, 'day')) > 30) {
        pushToast('warning', '조회 기간은 최대 30일을 넘을 수 없습니다.')
        return
      }

      axios.get('/fa/stats-menu-entry', {
        params: {
          startDate: query.value.startDate,
          endDate: query.value.endDate,
          isPrefixSum: query.value.isPrefixSum,
        },
      })
        .then(rs => {
          viewType.value = query.value.viewType

          results.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const excelDownload = async () => {
      if (viewType.value === 'summary') summaryDownload()
      else if (viewType.value === 'detail') detailDownload()
    }

    const summaryDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = '구분'
      worksheet.mergeCells(`A1:A2`)

      worksheet.getCell('B1').value = '닥터인포 홈'
      worksheet.mergeCells(`B1:C1`)

      worksheet.getCell('D1').value = '메디컬트렌드'
      worksheet.mergeCells(`D1:G1`)

      worksheet.getCell('H1').value = '약품정보'
      worksheet.mergeCells(`H1:K1`)

      worksheet.getCell('L1').value = '웹세미나'
      worksheet.mergeCells(`L1:O1`)

      worksheet.getCell('P1').value = '설문조사'
      worksheet.mergeCells(`P1:S1`)

      worksheet.getCell('T1').value = '포인트몰'
      worksheet.mergeCells(`T1:U1`)

      worksheet.getCell('V1').value = '이벤트'
      worksheet.mergeCells(`V1:Y1`)

      worksheet.getCell('Z1').value = '공지사항'
      worksheet.mergeCells(`Z1:AC1`)

      worksheet.getCell('AD1').value = '전체'
      worksheet.mergeCells(`AD1:AG1`)

      worksheet.getCell('B2').value = '유입 수'
      worksheet.getCell('C2').value = '유입자 수'

      worksheet.getCell('D2').value = '유입 수'
      worksheet.getCell('E2').value = '유입자 수'
      worksheet.getCell('F2').value = '클릭 수'
      worksheet.getCell('G2').value = '클릭자 수'

      worksheet.getCell('H2').value = '유입 수'
      worksheet.getCell('I2').value = '유입자 수'
      worksheet.getCell('J2').value = '클릭 수'
      worksheet.getCell('K2').value = '클릭자 수'

      worksheet.getCell('L2').value = '유입 수'
      worksheet.getCell('M2').value = '유입자 수'
      worksheet.getCell('N2').value = '클릭 수'
      worksheet.getCell('O2').value = '클릭자 수'

      worksheet.getCell('P2').value = '유입 수'
      worksheet.getCell('Q2').value = '유입자 수'
      worksheet.getCell('R2').value = '클릭 수'
      worksheet.getCell('S2').value = '클릭자 수'

      worksheet.getCell('T2').value = '유입 수'
      worksheet.getCell('U2').value = '유입자 수'

      worksheet.getCell('V2').value = '유입 수'
      worksheet.getCell('W2').value = '유입자 수'
      worksheet.getCell('X2').value = '클릭 수'
      worksheet.getCell('Y2').value = '클릭자 수'

      worksheet.getCell('Z2').value = '유입 수'
      worksheet.getCell('AA2').value = '유입자 수'
      worksheet.getCell('AB2').value = '클릭 수'
      worksheet.getCell('AC2').value = '클릭자 수'

      worksheet.getCell('AD2').value = '유입 수'
      worksheet.getCell('AE2').value = '유입자 수'
      worksheet.getCell('AF2').value = '클릭 수'
      worksheet.getCell('AG2').value = '클릭자 수'

      worksheet.columns = [
        { key: "date", width: 15 },
        { key: "homeEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "homeEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "pointMallEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "pointMallEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allEntryTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allEntryGroupCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allClickTotalCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allClickGroupCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      // eslint-disable-next-line array-callback-return
      results.value.map(x => {
        worksheet.addRow({
          date: (results.value.length === 1 && x.isPrefixSum) ? '누적 수' : (x.isPrefixSum ? "합계" : x.date),
          homeEntryTotalCount: x.homeEntryTotalMemberCount + x.homeEntryTotalNonMemberCount,
          homeEntryGroupCount: x.homeEntryGroupMemberCount + x.homeEntryGroupNonMemberCount,
          medicalTrendEntryTotalCount: x.medicalTrendEntryTotalMemberCount + x.medicalTrendEntryTotalNonMemberCount,
          medicalTrendEntryGroupCount: x.medicalTrendEntryGroupMemberCount + x.medicalTrendEntryGroupNonMemberCount,
          medicalTrendClickTotalCount: x.medicalTrendClickTotalMemberCount + x.medicalTrendClickTotalNonMemberCount,
          medicalTrendClickGroupCount: x.medicalTrendClickGroupMemberCount + x.medicalTrendClickGroupNonMemberCount,
          drugInfoEntryTotalCount: x.drugInfoEntryTotalMemberCount + x.drugInfoEntryTotalNonMemberCount,
          drugInfoEntryGroupCount: x.drugInfoEntryGroupMemberCount + x.drugInfoEntryGroupNonMemberCount,
          drugInfoClickTotalCount: x.drugInfoClickTotalMemberCount + x.drugInfoClickTotalNonMemberCount,
          drugInfoClickGroupCount: x.drugInfoClickGroupMemberCount + x.drugInfoClickGroupNonMemberCount,
          webSeminarEntryTotalCount: x.webSeminarEntryTotalMemberCount + x.webSeminarEntryTotalNonMemberCount,
          webSeminarEntryGroupCount: x.webSeminarEntryGroupMemberCount + x.webSeminarEntryGroupNonMemberCount,
          webSeminarClickTotalCount: x.webSeminarClickTotalMemberCount + x.webSeminarClickTotalNonMemberCount,
          webSeminarClickGroupCount: x.webSeminarClickGroupMemberCount + x.webSeminarClickGroupNonMemberCount,
          surveyEntryTotalCount: x.surveyEntryTotalMemberCount + x.surveyEntryTotalNonMemberCount,
          surveyEntryGroupCount: x.surveyEntryGroupMemberCount + x.surveyEntryGroupNonMemberCount,
          surveyClickTotalCount: x.surveyClickTotalMemberCount + x.surveyClickTotalNonMemberCount,
          surveyClickGroupCount: x.surveyClickGroupMemberCount + x.surveyClickGroupNonMemberCount,
          pointMallEntryTotalCount: x.pointMallEntryTotalMemberCount + x.pointMallEntryTotalNonMemberCount,
          pointMallEntryGroupCount: x.pointMallEntryGroupMemberCount + x.pointMallEntryGroupNonMemberCount,
          eventEntryTotalCount: x.eventEntryTotalMemberCount + x.eventEntryTotalNonMemberCount,
          eventEntryGroupCount: x.eventEntryGroupMemberCount + x.eventEntryGroupNonMemberCount,
          eventClickTotalCount: x.eventClickTotalMemberCount + x.eventClickTotalNonMemberCount,
          eventClickGroupCount: x.eventClickGroupMemberCount + x.eventClickGroupNonMemberCount,
          noticeEntryTotalCount: x.noticeEntryTotalMemberCount + x.noticeEntryTotalNonMemberCount,
          noticeEntryGroupCount: x.noticeEntryGroupMemberCount + x.noticeEntryGroupNonMemberCount,
          noticeClickTotalCount: x.noticeClickTotalMemberCount + x.noticeClickTotalNonMemberCount,
          noticeClickGroupCount: x.noticeClickGroupMemberCount + x.noticeClickGroupNonMemberCount,
          allEntryTotalCount: x.allEntryTotalMemberCount + x.allEntryTotalNonMemberCount,
          allEntryGroupCount: x.allEntryGroupMemberCount + x.allEntryGroupNonMemberCount,
          allClickTotalCount: x.allClickTotalMemberCount + x.allClickTotalNonMemberCount,
          allClickGroupCount: x.allClickGroupMemberCount + x.allClickGroupNonMemberCount,
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "메뉴조회현황 통계.xlsx")
    }

    const detailDownload = async () => {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("sheet1")

      worksheet.addRow()

      worksheet.getCell('A1').value = '구분'
      worksheet.mergeCells(`A1:A3`)

      worksheet.getCell('B1').value = '닥터인포 홈'
      worksheet.mergeCells(`B1:E1`)

      worksheet.getCell('F1').value = '메디컬트렌드'
      worksheet.mergeCells(`F1:M1`)

      worksheet.getCell('N1').value = '약품정보'
      worksheet.mergeCells(`N1:U1`)

      worksheet.getCell('V1').value = '웹세미나'
      worksheet.mergeCells(`V1:AC1`)

      worksheet.getCell('AD1').value = '설문조사'
      worksheet.mergeCells(`AD1:AK1`)

      worksheet.getCell('AL1').value = '포인트몰'
      worksheet.mergeCells(`AL1:AO1`)

      worksheet.getCell('AP1').value = '이벤트'
      worksheet.mergeCells(`AP1:AW1`)

      worksheet.getCell('AX1').value = '공지사항'
      worksheet.mergeCells(`AX1:BE1`)

      worksheet.getCell('BF1').value = '전체'
      worksheet.mergeCells(`BF1:BM1`)

      worksheet.getCell('B2').value = '유입 수'
      worksheet.mergeCells(`B2:C2`)
      worksheet.getCell('D2').value = '유입자 수'
      worksheet.mergeCells(`D2:E2`)

      worksheet.getCell('F2').value = '유입 수'
      worksheet.mergeCells(`F2:G2`)
      worksheet.getCell('H2').value = '유입자 수'
      worksheet.mergeCells(`H2:I2`)
      worksheet.getCell('J2').value = '클릭 수'
      worksheet.mergeCells(`J2:K2`)
      worksheet.getCell('L2').value = '클릭자 수'
      worksheet.mergeCells(`L2:M2`)

      worksheet.getCell('N2').value = '유입 수'
      worksheet.mergeCells(`N2:O2`)
      worksheet.getCell('P2').value = '유입자 수'
      worksheet.mergeCells(`P2:Q2`)
      worksheet.getCell('R2').value = '클릭 수'
      worksheet.mergeCells(`R2:S2`)
      worksheet.getCell('T2').value = '클릭자 수'
      worksheet.mergeCells(`T2:U2`)

      worksheet.getCell('V2').value = '유입 수'
      worksheet.mergeCells(`V2:W2`)
      worksheet.getCell('X2').value = '유입자 수'
      worksheet.mergeCells(`X2:Y2`)
      worksheet.getCell('Z2').value = '클릭 수'
      worksheet.mergeCells(`Z2:AA2`)
      worksheet.getCell('AB2').value = '클릭자 수'
      worksheet.mergeCells(`AB2:AC2`)

      worksheet.getCell('AD2').value = '유입 수'
      worksheet.mergeCells(`AD2:AE2`)
      worksheet.getCell('AF2').value = '유입자 수'
      worksheet.mergeCells(`AF2:AG2`)
      worksheet.getCell('AH2').value = '클릭 수'
      worksheet.mergeCells(`AH2:AI2`)
      worksheet.getCell('AJ2').value = '클릭자 수'
      worksheet.mergeCells(`AJ2:AK2`)

      worksheet.getCell('AL2').value = '유입 수'
      worksheet.mergeCells(`AL2:AM2`)
      worksheet.getCell('AN2').value = '유입자 수'
      worksheet.mergeCells(`AN2:AO2`)

      worksheet.getCell('AP2').value = '유입 수'
      worksheet.mergeCells(`AP2:AQ2`)
      worksheet.getCell('AR2').value = '유입자 수'
      worksheet.mergeCells(`AR2:AS2`)
      worksheet.getCell('AT2').value = '클릭 수'
      worksheet.mergeCells(`AT2:AU2`)
      worksheet.getCell('AV2').value = '클릭자 수'
      worksheet.mergeCells(`AV2:AW2`)

      worksheet.getCell('AX2').value = '유입 수'
      worksheet.mergeCells(`AX2:AY2`)
      worksheet.getCell('AZ2').value = '유입자 수'
      worksheet.mergeCells(`AZ2:BA2`)
      worksheet.getCell('BB2').value = '클릭 수'
      worksheet.mergeCells(`BB2:BC2`)
      worksheet.getCell('BD2').value = '클릭자 수'
      worksheet.mergeCells(`BD2:BE2`)

      worksheet.getCell('BF2').value = '유입 수'
      worksheet.mergeCells(`BF2:BG2`)
      worksheet.getCell('BH2').value = '유입자 수'
      worksheet.mergeCells(`BH2:BI2`)
      worksheet.getCell('BJ2').value = '클릭 수'
      worksheet.mergeCells(`BJ2:BK2`)
      worksheet.getCell('BL2').value = '클릭자 수'
      worksheet.mergeCells(`BL2:BM2`)

      // 회원,비회원 열은 A열인 '구분'을 제외하고 BN열 이전까지 계속 반복되어 알파벳 증감 함수 사용
      let columnAlphabet = 'B'
      let isMember = true
      while (columnAlphabet !== 'BN') {
        worksheet.getCell(`${columnAlphabet}3`).value = isMember ? '회원' : '비회원'

        isMember = !isMember
        columnAlphabet = incrementAlphabet(columnAlphabet)
      }

      worksheet.columns = [
        { key: "date", width: 15 },
        { key: "homeEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "homeEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "homeEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "homeEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "medicalTrendClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "drugInfoClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "webSeminarClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "surveyClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "pointMallEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "pointMallEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "pointMallEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "pointMallEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "eventClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "noticeClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allEntryTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allEntryTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allEntryGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allEntryGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allClickTotalMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allClickTotalNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allClickGroupMemberCount", width: 15, style: { numFmt: '#,##0' } },
        { key: "allClickGroupNonMemberCount", width: 15, style: { numFmt: '#,##0' } },
      ]

      // eslint-disable-next-line array-callback-return
      results.value.map(x => {
        worksheet.addRow({
          date: (results.value.length === 1 && x.isPrefixSum) ? '누적 수' : (x.isPrefixSum ? "합계" : x.date),
          homeEntryTotalMemberCount: x.homeEntryTotalMemberCount,
          homeEntryTotalNonMemberCount: x.homeEntryTotalNonMemberCount,
          homeEntryGroupMemberCount: x.homeEntryGroupMemberCount,
          homeEntryGroupNonMemberCount: x.homeEntryGroupNonMemberCount,
          medicalTrendEntryTotalMemberCount: x.medicalTrendEntryTotalMemberCount,
          medicalTrendEntryTotalNonMemberCount: x.medicalTrendEntryTotalNonMemberCount,
          medicalTrendEntryGroupMemberCount: x.medicalTrendEntryGroupMemberCount,
          medicalTrendEntryGroupNonMemberCount: x.medicalTrendEntryGroupNonMemberCount,
          medicalTrendClickTotalMemberCount: x.medicalTrendClickTotalMemberCount,
          medicalTrendClickTotalNonMemberCount: x.medicalTrendClickTotalNonMemberCount,
          medicalTrendClickGroupMemberCount: x.medicalTrendClickGroupMemberCount,
          medicalTrendClickGroupNonMemberCount: x.medicalTrendClickGroupNonMemberCount,
          drugInfoEntryTotalMemberCount: x.drugInfoEntryTotalMemberCount,
          drugInfoEntryTotalNonMemberCount: x.drugInfoEntryTotalNonMemberCount,
          drugInfoEntryGroupMemberCount: x.drugInfoEntryGroupMemberCount,
          drugInfoEntryGroupNonMemberCount: x.drugInfoEntryGroupNonMemberCount,
          drugInfoClickTotalMemberCount: x.drugInfoClickTotalMemberCount,
          drugInfoClickTotalNonMemberCount: x.drugInfoClickTotalNonMemberCount,
          drugInfoClickGroupMemberCount: x.drugInfoClickGroupMemberCount,
          drugInfoClickGroupNonMemberCount: x.drugInfoClickGroupNonMemberCount,
          webSeminarEntryTotalMemberCount: x.webSeminarEntryTotalMemberCount,
          webSeminarEntryTotalNonMemberCount: x.webSeminarEntryTotalNonMemberCount,
          webSeminarEntryGroupMemberCount: x.webSeminarEntryGroupMemberCount,
          webSeminarEntryGroupNonMemberCount: x.webSeminarEntryGroupNonMemberCount,
          webSeminarClickTotalMemberCount: x.webSeminarClickTotalMemberCount,
          webSeminarClickTotalNonMemberCount: x.webSeminarClickTotalNonMemberCount,
          webSeminarClickGroupMemberCount: x.webSeminarClickGroupMemberCount,
          webSeminarClickGroupNonMemberCount: x.webSeminarClickGroupNonMemberCount,
          surveyEntryTotalMemberCount: x.surveyEntryTotalMemberCount,
          surveyEntryTotalNonMemberCount: x.surveyEntryTotalNonMemberCount,
          surveyEntryGroupMemberCount: x.surveyEntryGroupMemberCount,
          surveyEntryGroupNonMemberCount: x.surveyEntryGroupNonMemberCount,
          surveyClickTotalMemberCount: x.surveyClickTotalMemberCount,
          surveyClickTotalNonMemberCount: x.surveyClickTotalNonMemberCount,
          surveyClickGroupMemberCount: x.surveyClickGroupMemberCount,
          surveyClickGroupNonMemberCount: x.surveyClickGroupNonMemberCount,
          pointMallEntryTotalMemberCount: x.pointMallEntryTotalMemberCount,
          pointMallEntryTotalNonMemberCount: x.pointMallEntryTotalNonMemberCount,
          pointMallEntryGroupMemberCount: x.pointMallEntryGroupMemberCount,
          pointMallEntryGroupNonMemberCount: x.pointMallEntryGroupNonMemberCount,
          eventEntryTotalMemberCount: x.eventEntryTotalMemberCount,
          eventEntryTotalNonMemberCount: x.eventEntryTotalNonMemberCount,
          eventEntryGroupMemberCount: x.eventEntryGroupMemberCount,
          eventEntryGroupNonMemberCount: x.eventEntryGroupNonMemberCount,
          eventClickTotalMemberCount: x.eventClickTotalMemberCount,
          eventClickTotalNonMemberCount: x.eventClickTotalNonMemberCount,
          eventClickGroupMemberCount: x.eventClickGroupMemberCount,
          eventClickGroupNonMemberCount: x.eventClickGroupNonMemberCount,
          noticeEntryTotalMemberCount: x.noticeEntryTotalMemberCount,
          noticeEntryTotalNonMemberCount: x.noticeEntryTotalNonMemberCount,
          noticeEntryGroupMemberCount: x.noticeEntryGroupMemberCount,
          noticeEntryGroupNonMemberCount: x.noticeEntryGroupNonMemberCount,
          noticeClickTotalMemberCount: x.noticeClickTotalMemberCount,
          noticeClickTotalNonMemberCount: x.noticeClickTotalNonMemberCount,
          noticeClickGroupMemberCount: x.noticeClickGroupMemberCount,
          noticeClickGroupNonMemberCount: x.noticeClickGroupNonMemberCount,
          allEntryTotalMemberCount: x.allEntryTotalMemberCount,
          allEntryTotalNonMemberCount: x.allEntryTotalNonMemberCount,
          allEntryGroupMemberCount: x.allEntryGroupMemberCount,
          allEntryGroupNonMemberCount: x.allEntryGroupNonMemberCount,
          allClickTotalMemberCount: x.allClickTotalMemberCount,
          allClickTotalNonMemberCount: x.allClickTotalNonMemberCount,
          allClickGroupMemberCount: x.allClickGroupMemberCount,
          allClickGroupNonMemberCount: x.allClickGroupNonMemberCount,
        })
      })

      worksheet.eachRow({ includeEmpty: true }, (row, number) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (number === 1 || number === 2 || number === 3) {
            // eslint-disable-next-line no-param-reassign
            cell.font = {
              bold: true,
            }

            // eslint-disable-next-line no-param-reassign
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'D3D3D3' },
            }
          }

          // eslint-disable-next-line no-param-reassign
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }

          if (cell.value === '소계' || cell.value === '합계') {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          } else {
            // eslint-disable-next-line no-param-reassign
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'left',
            }
          }
        })
      })

      const mimeType = {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }

      const buffer = await workbook.xlsx.writeBuffer()

      const blob = new Blob([buffer], mimeType)

      saveAs(blob, "메뉴조회현황 통계.xlsx")
    }

    return {
      query,
      viewType,
      fetchData,
      headers,
      results,
      pageCount,
      excelDownload,
      changePrefixSum,
    }
  },
}
</script>
